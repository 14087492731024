import { TopFilters } from 'Shared/types/dashboard';
import {
  AddNewTopFilterProps,
  GenerateNewTopFiltersProps,
  GetTopFiltersFromUrlProps,
} from './types';

const pagesWithTabs = ['contributions', 'respondents', 'visitors', 'trends-ai'];
const topFiltersMap = {
  contributions: ['proposals', 'date', 'sentiment'],
  respondents: ['date', 'status'],
  visitors: ['date'],
  overview: ['date'],
  'trends-ai': ['date', 'removeTopic'],
};

export const removeEmptyTopFilters = (topFilters: TopFilters) => {
  const filtersWithValues = Object.keys(topFilters).filter((filterKey) => {
    if (!topFilters[filterKey].length) return false;
    return true;
  });
  const topFiltersWithAnswers = filtersWithValues.reduce(
    (accumulator, filterWithValue) => {
      accumulator[filterWithValue] = topFilters[filterWithValue];
      return accumulator;
    },
    {}
  );
  return topFiltersWithAnswers;
};

export const generateNewTopFilters = ({
  topFilters,
  newFilter,
  page,
}: GenerateNewTopFiltersProps) => {
  const filterKeys = topFiltersMap[page];

  const newFilterKey = Object.keys(newFilter)[0];
  const parsedTopFilters = topFilters
    ? (JSON.parse(topFilters) as TopFilters)
    : {};
  const appliedFiltersKeys = Object.keys(parsedTopFilters);
  const newTopFilters = filterKeys.reduce((accumulator, filterKey) => {
    if (filterKey === newFilterKey) {
      accumulator[newFilterKey] = newFilter[filterKey];
      return accumulator;
    }
    const appliedFiltersKey = appliedFiltersKeys.find(
      (appliedKey) => appliedKey === filterKey
    );
    if (appliedFiltersKey) {
      accumulator[appliedFiltersKey] = parsedTopFilters[appliedFiltersKey];
      return accumulator;
    }
    return accumulator;
  }, {});
  const filteredTopFilters = removeEmptyTopFilters(newTopFilters);
  return filteredTopFilters;
};

export const addNewTopFilter = ({
  page,
  router,
  newFilter,
}: AddNewTopFilterProps) => {
  const { tab = '[]', topFilters, nestedFilters, ...rest } = router.query;
  const newTopFilters = generateNewTopFilters({
    topFilters: topFilters as string,
    newFilter,
    page,
  });

  const isProposalsUpdate = Boolean(newFilter.proposals);

  router.replace({
    pathname: `/v5/dashboard/${page}`,
    query: {
      ...(pagesWithTabs.includes(page) && { tab }),
      ...(Object.keys(newTopFilters).length && {
        topFilters: JSON.stringify(newTopFilters),
      }),
      ...(!isProposalsUpdate && { nestedFilters }),
      ...rest,
    },
  });
};

export const getTopFiltersFromUrl = ({ router }: GetTopFiltersFromUrlProps) => {
  const { query } = router;
  const urlTopFilters = JSON.parse((query?.topFilters as string) || '{}');
  const { date, ...rest } = urlTopFilters;
  const [from, to] = date || [];
  return { date: { from, to }, ...rest };
};
