import { theme } from 'Theme';

export const statusData = [
  {
    label: 'Confirmed',
    color: theme.colors.green['A400'],
    id: 'confirmed',
  },
  {
    label: 'Pending',
    color: theme.colors.blue['A700'],
    id: 'pending',
  },
  {
    label: 'Anonymous',
    color: theme.colors.purple[700],
    id: 'anonymous',
  },
  {
    label: 'Surveyed',
    color: theme.colors.purple[800],
    id: 'surveyed',
  },
];
