import * as React from 'react';
import { useRouter } from 'next/router';
import { TopicPill } from 'Client/pages/dashboard/topicAi/components/TopicPill/TopicPill';
import { redirectToSliderTab } from 'Client/pages/dashboard/utils/tabUrlHelpers';
import { Wrapper } from './TopicCell.styles';

interface TopicCellProps {
  value: string | number;
  cell: {
    row: {
      original: {
        color: string;
        ai: boolean;
        _id: string;
        contributionId: string;
        question: string;
        topic: string;
      };
    };
    column: {
      width: number;
    };
  };
}

export const TopicCell: React.FC<TopicCellProps> = ({
  value,
  cell,
}: TopicCellProps) => {
  const router = useRouter();
  const { tab } = router.query;

  if (!value && value !== 0) return <span>-</span>;

  const {
    row: {
      original: { color, _id, question, topic, ai },
    },
    column: { width },
  } = cell;

  return (
    <Wrapper
      onClick={() => {
        if (tab === 'comments-list') {
          redirectToSliderTab({
            newTab: 'comments',
            page: 'trends-ai',
            router,
            id: `${topic} ${_id} ${question}`,
          });
          return;
        }

        if (tab === 'topics') {
          redirectToSliderTab({
            newTab: 'topicSummary',
            page: 'trends-ai',
            router,
            id: topic,
          });
          return;
        }
      }}
    >
      <TopicPill
        color={color}
        type={ai ? 'AI' : 'Custom'}
        text={String(value)}
        isTable
        columnWidth={width}
      />
    </Wrapper>
  );
};
