import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ color: string }>`
  margin: 0;
  display: flex;
  align-items: center;
  ${({ color }) => css`
    :hover {
      color: ${color};
    }
    div {
      background: ${color} !important;
    }
  `}
`;

export const SentimentCircle = styled.div`
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
  margin-right: 0.5625rem;
`;
