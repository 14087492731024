import { theme } from 'Theme';

export const sentimentOptions = [
  {
    color: theme.colors.red[600],
    value: 0,
    label: 'Negative',
    oldLabel: 'Unhappy',
  },
  {
    color: theme.colors.orange[700],
    value: 25,
    label: 'Mostly negative',
    oldLabel: 'Dissatisfied',
  },
  {
    color: theme.colors.orange[500],
    value: 50,
    label: 'Neutral',
    oldLabel: 'Neutral',
  },
  {
    color: theme.colors.green[300],
    value: 75,
    label: 'Mostly positive',
    oldLabel: 'Satisfied',
  },
  {
    color: theme.colors.green['A700'],
    value: 100,
    label: 'Positive',
    oldLabel: 'Happy',
  },
];

export const mapSentiments = (
  sentiment: string | number
): { color: string; value: number; label: string; oldLabel: string } => {
  let sentimentObj = null;
  sentimentOptions.map((s) => {
    Object.keys(s).map((key) => {
      if (s[key] === sentiment) {
        sentimentObj = s;
        return;
      }
    });
  });

  return sentimentObj;
};
