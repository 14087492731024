import { DateCell } from '../components/CustomTableCells/DateCell/DateCell';
import { GroupCell } from '../components/CustomTableCells/GroupCell/GroupCell';
import { SubGroupCell } from '../components/CustomTableCells/GroupCell/SubGroupCell';
import { MapCell } from '../components/CustomTableCells/MapCell';
import { SmilieCell } from '../components/CustomTableCells/SmilieCell';
import { StatusCell } from '../components/CustomTableCells/StatusCell/StatusCell';
import { TopicCell } from '../components/CustomTableCells/TopicCell/TopicCell';
import { UploadedDocumentCell } from '../components/CustomTableCells/UploadedDocumentCell';

export default function customCellsMapper(cellId: string) {
  const customCells = [
    { id: 'date', component: DateCell },
    { id: 'createdAt', component: DateCell },
    { id: 'status', component: StatusCell },
    { id: 'mapLink', component: MapCell },
    { id: 'smilie', component: SmilieCell },
    { id: 'respondent-file-upload', component: UploadedDocumentCell },
    { id: 'topicName', component: TopicCell },
    { id: 'group', component: GroupCell },
    { id: 'subGroup', component: SubGroupCell },
  ];

  const customCell = customCells.find((c) => c.id === cellId);

  if (!customCell) return null;

  return customCell.component;
}
