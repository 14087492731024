import * as React from 'react';
import { useProject } from 'Client/utils/hooks';
import { makeRedirectDomain } from 'Client/utils/url';
import { Wrapper } from './MapCell.styles';
import { MapCellProps } from './types';

export const MapCell: React.FC<MapCellProps> = ({ cell }: MapCellProps) => {
  const { id: projectId } = useProject();
  const { _id, surveySlug, proposal, surveyId, latitude, longitude } =
    cell.row.original;
  const mapSlug = proposal || surveySlug || surveyId;

  const domainLink = makeRedirectDomain(projectId);
  const linkToRedirect = `http://${domainLink}/map/${mapSlug}?cid=${_id}`;
  if (
    !latitude ||
    latitude === '-' ||
    !longitude ||
    longitude === '-' ||
    !mapSlug
  ) {
    return <span>-</span>;
  }
  return (
    <Wrapper target={'_blank'} href={linkToRedirect}>
      See in map
    </Wrapper>
  );
};
