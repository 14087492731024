import * as React from 'react';
import styled, { css } from 'styled-components';

const Button = styled.button`
  background-color: transparent;
`;

export const Wrapper = styled.div`
  margin: 2rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  border-bottom: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};
`;

export const TabItem = styled(Button)<{ isSelected: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-right: 2rem;
  border: none;
  cursor: pointer;
  ${({ isSelected, theme }) => css`
    border-bottom: ${isSelected ? '0.25rem solid' : 'none'};
    border-bottom-color: ${theme.colors.green['A400']};
  `}
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const OptionsButtons = styled(({ ...props }) => <Button {...props} />)`
  margin-right: 1rem;
  cursor: pointer;
  border: 0;
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green[500]};
  display: flex;
  align-items: baseline;
  margin-left: 1rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white[500]};
  }
  svg {
    align-self: flex-end;
  }
  span {
    margin-left: 0.2rem;
  }
`;

export const ManageGroupsButton = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};
    font-family: ${theme.fontFamily};
    font-weight: 700;
    font-size: 0.938rem;
    padding: 0.438rem 1.25rem 0.438rem 1.25rem;
    border: none;
    border-radius: 0.313rem;
    cursor: pointer;
    opacity: none;
  `}
`;
