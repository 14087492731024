import { theme } from 'Theme';

export const sentimentsData = [
  {
    label: 'Positive',
    color: theme.colors.green['A700'],
    id: '100',
  },
  {
    label: 'Mostly positive',
    color: theme.colors.green[300],
    id: '75',
  },
  {
    label: 'Neutral',
    color: theme.colors.orange[500],
    id: '50',
  },
  {
    label: 'Mostly negative',
    color: theme.colors.orange[700],
    id: '25',
  },
  {
    label: 'Negative',
    color: theme.colors.red[600],
    id: '0',
  },
  {
    label: 'No sentiment',
    color: theme.colors.grey[600],
    id: 'noSentiment',
  },
];
