import * as React from 'react';
import { IconProps } from '../types';

export const PlusCircleIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="bold / plus-circle">
      <path
        id="shape (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3501 3.0625C5.62319 3.0625 3.4126 5.27309 3.4126 8C3.4126 10.7269 5.62319 12.9375 8.3501 12.9375C11.077 12.9375 13.2876 10.7269 13.2876 8C13.2876 5.27309 11.077 3.0625 8.3501 3.0625ZM2.0376 8C2.0376 4.5137 4.8638 1.6875 8.3501 1.6875C11.8364 1.6875 14.6626 4.5137 14.6626 8C14.6626 11.4863 11.8364 14.3125 8.3501 14.3125C4.8638 14.3125 2.0376 11.4863 2.0376 8ZM8.3501 4.8125C8.72979 4.8125 9.0376 5.1203 9.0376 5.5V7.3125H10.8501C11.2298 7.3125 11.5376 7.6203 11.5376 8C11.5376 8.3797 11.2298 8.6875 10.8501 8.6875H9.0376V10.5C9.0376 10.8797 8.72979 11.1875 8.3501 11.1875C7.9704 11.1875 7.6626 10.8797 7.6626 10.5V8.6875H5.8501C5.4704 8.6875 5.1626 8.3797 5.1626 8C5.1626 7.6203 5.4704 7.3125 5.8501 7.3125H7.6626V5.5C7.6626 5.1203 7.9704 4.8125 8.3501 4.8125Z"
        fill={color}
      />
    </g>
  </svg>
);
