import * as React from 'react';
import { mapStatusPillsValues } from 'Pages/dashboard/respondents/components/Header/utils';
import { StatusCellProps } from './types';
import { Wrapper, StatusCircle } from './StatusCell.styles';

const StatusCell: React.FC<StatusCellProps> = ({ value }: StatusCellProps) => {
  if (!value) return <span>-</span>;
  const statusMapping = mapStatusPillsValues({
    confirmed: 0,
    pending: 0,
    anonymous: 0,
    surveyed: 0,
  });
  const status = statusMapping.find(
    (status) => status.label.toLocaleLowerCase() === value
  );
  return (
    <Wrapper color={status?.color}>
      <StatusCircle />
      <span>{value}</span>
    </Wrapper>
  );
};

export { StatusCell };
