import { ContributionStatus } from 'Shared/types/contribution';
import {
  EQUALS,
  NOT_EQUAL,
  NULL,
  NOT_NULL,
  CONTAINS,
  NOT_CONTAIN,
  STARTS_WITH,
  NOT_START_WITH,
  GREATER_THAN,
  LESS_THAN,
} from 'Shared/constants';

const { ANONYMOUS, CONFIRMED, PENDING, SURVEYED } = ContributionStatus;
const baseRules = [EQUALS, NOT_EQUAL, NULL, NOT_NULL];
const ruleSets = {
  textGroup: [...baseRules, CONTAINS, NOT_CONTAIN, STARTS_WITH, NOT_START_WITH],
  strictTextGroup: [...baseRules],
  numberGroup: [...baseRules, GREATER_THAN, LESS_THAN],
};

export const nullTypes = [NULL, NOT_NULL];
export const freeTextTypes = [
  STARTS_WITH,
  NOT_START_WITH,
  CONTAINS,
  NOT_CONTAIN,
];

export const numberQuestionTypes = ['smilie', 'rating-number'];
export const freeTextQuestionTypes = [
  'group',
  'label',
  'text',
  'textarea',
  'textpoll',
  'imagepoll',
];

export const topFilters = ['date'];
export const filterGroup = ['And', 'Or'];

export const propertiesTypesRuleSets = [
  {
    type: 'status',
    ruleSets: [EQUALS, NOT_EQUAL],
    possibleValues: [ANONYMOUS, CONFIRMED, PENDING, SURVEYED],
  },
  {
    type: 'date',
    ruleSets: ruleSets.numberGroup,
  },
  {
    type: 'group',
    ruleSets: ruleSets.textGroup,
  },
  {
    type: 'smilie',
    ruleSets: ruleSets.numberGroup,
  },
  {
    type: 'label',
    ruleSets: ruleSets.textGroup,
  },
  { type: 'priorities', ruleSets: ruleSets.strictTextGroup },
  { type: 'imagepoll', ruleSets: ruleSets.textGroup },
  { type: 'text', ruleSets: ruleSets.textGroup },
  { type: 'textarea', ruleSets: ruleSets.textGroup },
  { type: 'textpoll', ruleSets: ruleSets.textGroup },
  { type: 'rating-number', ruleSets: ruleSets.numberGroup },
  { type: 'moodSwing', ruleSets: ruleSets.strictTextGroup },
];
