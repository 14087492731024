import { theme } from 'Theme';

export const statusOptions = [
  {
    color: theme.colors.green['A400'],
    label: 'Confirmed',
  },
  {
    color: theme.colors.orange['A100'],
    label: 'Pending',
  },
  {
    color: theme.colors.purple['800'],
    label: 'Surveyed',
  },
  {
    color: theme.colors.purple['700'],
    label: 'Anonymous',
  },
];

export const mapStatus = (status: string): { color: string; label: string } => {
  if (!status) return null;
  const mappedStatus = statusOptions.find(
    (s) => s.label.toLowerCase() === status.toLocaleLowerCase()
  );
  return mappedStatus;
};
