import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Circle = styled.span<{ color?: string }>`
  height: 1.3rem;
  width: 1.3rem;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.green[500]};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.625rem;
`;

export const HalfCircleBackground = styled.div<{ color?: string }>`
  height: 1.4rem;
  width: 1.3rem;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.green[500]} !important;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 0.625rem;
`;

export const HalfCircleInside = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]} !important;
  border-bottom-right-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  height: 1rem;
  width: 0.5rem;
  position: absolute;
  right: 0.188rem;
  bottom: 0.2rem;
`;
