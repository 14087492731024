import * as React from 'react';
import { IndicatorProps, OptionTypeBase, components } from 'react-select';
import { ArrowDownIcon } from 'Atoms/Icons/ArrowDownIcon/ArrowDownIcon';

// Component for using custom Icon on dropdown indicator
export const DropdownIndicator = (
  props: IndicatorProps<OptionTypeBase, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
};
