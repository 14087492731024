import styled from 'styled-components';
import { opacify, lighten } from 'polished';
import { StyledReactSelect } from 'Client/components/molecules/SimpleSelect/components';

// Updated styles from SimpleSelect component
export const DropdownSelect = styled(StyledReactSelect)<{
  zIndexMenu: number;
  zIndexInput: number;
  validations?: { error?: string };
}>`
  width: 100%;

  .react-select__control {
    border-radius: 0.5rem;
    border: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
    height: fit-content;
    width: 100%;
    position: relative;
    z-index: ${({ zIndexInput }) => zIndexInput};
    margin: 0;

    &:hover {
      border: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
    }

    &.react-select__control--is-focused {
      border: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
      ::after {
        border: none;
      }
    }

    &.react-select__control--menu-is-open {
      border-bottom: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
      margin: 0;
      &.react-select__control--is-focused:after {
        display: none;
      }

      box-shadow: 0 0.25rem 0.375rem 0
        ${({ theme }) => opacify(-0.9, theme.colors.black[500])};
    }

    .react-select__placeholder {
      color: ${({ theme }) => theme.colorMappings.placeholderColor};
      font-family: Source Sans Pro;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-overflow: ellipsis;
    }

    .react-select__value-container {
      min-height: 2.875rem;
      padding: 0.125rem 0.625rem;

      .react-select__single-value {
        display: flex;

        span {
          font-weight: 400;
          font-size: 1.125rem;
        }
      }
    }

    .react-select__indicator-separator {
      display: flex;
      background-color: ${({ theme }) => theme.colors.white[300]};
      width: 0.063rem;
      margin: 0;
    }
    .react-select__clear-indicator,
    .react-select__dropdown-indicator {
      height: 100%;
      z-index: 100000;
      display: flex;
      padding: 0;
      width: 4rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0 0.5rem 0.5rem 0;
      background: ${({ theme }) => theme.colors.white[400]};
      cursor: pointer;
    }

    .react-select__clear-indicator {
      border-radius: 0;

      &:hover {
        background-color: ${({ theme }) => theme.colorMappings.lightRed};

        svg {
          fill: ${({ theme }) => theme.colorMappings.red};
        }
      }
    }
  }

  .react-select__menu {
    border-radius: 0.5rem;
    position: absolute;
    width: 99.3%;
    bottom: 10;
    margin-top: -1rem;
    padding-top: 1rem;
    margin-left: 0.4%;
    z-index: ${({ zIndexMenu }) => zIndexMenu};

    .react-select__menu-list {
      padding-top: 0.75rem;
    }

    .react-select__option {
      border-radius: 1rem;
      display: flex;
      white-space: break-spaces;
      word-break: break-word;
      padding: 0 0 0 1.25rem;
      min-height: 2rem;
      line-height: 1.5rem;
      .MuiListItemText-root {
        margin: 0.125rem 0;
      }

      span {
        color: ${({ theme }) => theme.colorMappings.placeholderColor};
        font-weight: 400;
        font-size: 1.125rem;
      }

      &:hover {
        cursor: pointer;
      }

      &.react-select__option--is-focused {
        background-color: transparent;
      }

      &.react-select__option--is-selected {
        background-color: transparent;
      }
    }
  }

  .react-select__control--is-disabled {
    pointer-events: all;
    cursor: not-allowed;

    .react-select__dropdown-indicator {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  ${({ validations }) => `
    ${
      validations?.error
        ? `
      border: 1px solid #F02D2D;
      outline: 3px solid ${lighten(0.4, '#F02D2D')};
      border-radius: 0.5rem
      `
        : ''
    }
  `}
`;
