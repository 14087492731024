import * as React from 'react';
import { FileUploadIcon } from 'Icons';
import { Wrapper } from './UploadedDocumentCell.styles';
import { UploadedDocumentCellProps } from './types';

export const UploadedDocumentCell: React.FC<UploadedDocumentCellProps> = ({
  value,
}: UploadedDocumentCellProps) => {
  return (
    <Wrapper>
      {value && value !== '-' && <FileUploadIcon />}
      <span>{value}</span>
    </Wrapper>
  );
};
