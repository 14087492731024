import * as React from 'react';
import { sentimentsData } from 'Pages/dashboard/contributions/constants';
import { OptionItem } from 'Client/types';
import { Wrapper, SentimentCircle } from './FeelingCell.styles';
import { SmilieCellProps } from './types';

export const SmilieCell: React.FC<SmilieCellProps> = ({
  value,
  cell,
}: SmilieCellProps) => {
  if (!value && value !== 0) return <span>-</span>;

  const { column, row } = cell;
  const questionId = column.id.split(':')[1]; // id = questionName:questionId;
  const { answersPopulated } = row.original;
  const answerPopulated = answersPopulated.find(
    (answer) => answer.questionId === questionId
  );

  if (!answerPopulated) return <span>-</span>;

  const questionOptions = answerPopulated.questionContent.options;
  const currentOption = (questionOptions as Array<OptionItem>).find(
    (option) => option.value === value
  );

  const sentiment = sentimentsData.find(
    (sentiment) => parseInt(sentiment.id) === value
  );

  return (
    <Wrapper color={sentiment.color}>
      <SentimentCircle />
      <span>{currentOption.label}</span>
    </Wrapper>
  );
};
