import { HandleTabUrlProps, RedirectToTabProps } from './types';

export const handleTabUrls = ({
  page,
  tabInQuery,
  router,
  tabOptions,
  setTab,
}: HandleTabUrlProps) => {
  const defaultTab = tabOptions[0];

  if (!tabInQuery) {
    redirectToTab({ newTab: defaultTab.id, router, page });
    return;
  }

  const newTab = tabOptions.find((tab) => tab.id === tabInQuery);

  if (!newTab) {
    redirectToTab({ newTab: defaultTab.id, router, page });
    return;
  }

  setTab(newTab.value);
};

export const redirectToTab = ({ newTab, page, router }: RedirectToTabProps) => {
  const newQuery = router.query;

  delete newQuery.tab;
  delete newQuery.id;
  delete newQuery.sliderTab;

  router.replace({
    pathname: `/v5/dashboard/${page}`,
    query: { tab: newTab, ...newQuery },
  });
};

export const handleSliderTabUrls = ({
  page,
  tabInQuery,
  router,
  tabOptions,
  setTab,
  selectedComment,
}: HandleTabUrlProps) => {
  const defaultTab = tabOptions[0];
  if (!tabInQuery && selectedComment) {
    redirectToSliderTab({ newTab: defaultTab.id, router, page });
    return;
  }
  const newTab = tabOptions.find((tab) => tab.id === tabInQuery);
  if (!newTab && selectedComment) {
    redirectToSliderTab({ newTab: defaultTab.id, router, page });
    return;
  }

  if (!newTab && !selectedComment) {
    return;
  }

  setTab(newTab.value);
  return;
};

export const redirectToSliderTab = ({
  newTab,
  page,
  router,
  id,
}: RedirectToTabProps) => {
  const newQuery = router.query;
  delete newQuery.sliderTab;

  if (id) {
    newQuery.id = id;
  }

  if (!newTab) {
    delete newQuery.id;
    router.replace({
      pathname: `/v5/dashboard/${page}`,
      query: { ...newQuery },
    });
  }
  router.replace({
    pathname: `/v5/dashboard/${page}`,
    query: { sliderTab: newTab, ...newQuery },
  });
};
