import * as React from 'react';
import { Circle, Wrapper } from './GroupCell.styles';

interface GroupCellProps {
  value: string | number;
  cell: {
    row: {
      original: {
        groupColour: string;
        type: string;
        _id: string;
        contributionId: string;
      };
    };
  };
}

export const GroupCell: React.FC<GroupCellProps> = ({
  value,
  cell,
}: GroupCellProps) => {
  if (!value && value !== 0) return <span>-</span>;

  const {
    row: {
      original: { groupColour },
    },
  } = cell;

  return (
    <Wrapper>
      <Circle color={groupColour} />
      <span>{String(value)}</span>
    </Wrapper>
  );
};
