import * as React from 'react';
import { IconProps } from '../../types';

export const AIIcon: React.FC<IconProps> = ({
  width = 15,
  height = 16,
  color = 'black',
  viewBox = '0 0 15 16',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.8975 7.7475L5.665 8.4975H7.1425L6.91 7.7475C6.82 7.4725 6.735 7.2 6.655 6.93C6.575 6.655 6.495 6.375 6.415 6.09H6.385C6.31 6.375 6.2325 6.655 6.1525 6.93C6.0725 7.2 5.9875 7.4725 5.8975 7.7475ZM4.3975 10.5L6.0625 5.58H6.7675L8.4325 10.5H7.765L7.3 9H5.5075L5.035 10.5H4.3975ZM8.97959 10.5V5.58H9.60209V10.5H8.97959Z"
        fill={color}
      />
      <rect
        x="2.1875"
        y="2.6875"
        width="10.375"
        height="10.625"
        rx="5.1875"
        stroke={color}
        strokeWidth="0.625"
      />
    </svg>
  );
};
