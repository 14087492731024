import * as React from 'react';

export const ArrowDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#455A64"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22208 8.22221C3.65166 7.79263 4.34814 7.79263 4.77772 8.22221L11.9999 15.4444L19.2221 8.22221C19.6517 7.79263 20.3481 7.79263 20.7777 8.22221C21.2073 8.65178 21.2073 9.34827 20.7777 9.77784L12.7777 17.7778C12.3481 18.2074 11.6517 18.2074 11.2221 17.7778L3.22208 9.77784C2.79251 9.34827 2.79251 8.65178 3.22208 8.22221Z"
        fill="#455A64"
      />
    </svg>
  );
};
