import * as React from 'react';
import { CalendarSvg } from './CalendarIcon.styles';
import { IconProps } from '../types';

export const CalendarIcon: React.FC<IconProps> = ({
  width = 24,
  height = 24,
  fill = '#455A64',
  ...props
}) => {
  return (
    <CalendarSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9999 1.90002C9.60742 1.90002 10.0999 2.39251 10.0999 3.00002V3.90002H13.8999V3.00002C13.8999 2.39251 14.3924 1.90002 14.9999 1.90002C15.6074 1.90002 16.0999 2.39251 16.0999 3.00002V3.90002H17.9999C19.712 3.90002 21.0999 5.28794 21.0999 7.00002V19C21.0999 20.7121 19.712 22.1 17.9999 22.1H5.9999C4.28782 22.1 2.8999 20.7121 2.8999 19V7.00002C2.8999 5.28794 4.28782 3.90002 5.9999 3.90002H7.8999V3.00002C7.8999 2.39251 8.39239 1.90002 8.9999 1.90002ZM7.8999 6.10002H5.9999C5.50285 6.10002 5.0999 6.50297 5.0999 7.00002V9.90002H18.8999V7.00002C18.8999 6.50297 18.497 6.10002 17.9999 6.10002H16.0999V7.00002C16.0999 7.60754 15.6074 8.10002 14.9999 8.10002C14.3924 8.10002 13.8999 7.60754 13.8999 7.00002V6.10002H10.0999V7.00002C10.0999 7.60754 9.60742 8.10002 8.9999 8.10002C8.39239 8.10002 7.8999 7.60754 7.8999 7.00002V6.10002ZM18.8999 12.1H5.0999V19C5.0999 19.4971 5.50285 19.9 5.9999 19.9H17.9999C18.497 19.9 18.8999 19.4971 18.8999 19V12.1Z"
        fill={fill}
      />
    </CalendarSvg>
  );
};
