import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  svg {
    min-height: 1.6rem;
    min-width: 1.6rem;
    margin-right: 0.4rem;
  }
`;
