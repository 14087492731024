import { HeaderProps, CellProps } from 'react-table';
import { TableHeaders, Column } from 'Pages/dashboard/components/Table/types';
import customCellsMapper from './customCellsMapper';

interface Props {
  baseColumns: TableHeaders[];
  CheckboxColumnHeader: HeaderProps;
  CheckboxCell: CellProps;
  pinnedColumn?: Column;
}

const headersSharedConfig = {
  disableResizing: true,
};

export default function generateTableColumns({
  CheckboxColumnHeader,
  CheckboxCell,
  baseColumns,
  pinnedColumn,
}: Props) {
  const pinnedHeaderId = 'selection';

  const selectionHeader: TableHeaders = {
    id: pinnedHeaderId,
    Header: '',
    sticky: 'left',
    columns: [
      {
        id: 'row-selection',
        Cell: CheckboxCell,
        Header: CheckboxColumnHeader,
        width: 50,
        disableResizing: true,
      },
      ...(pinnedColumn ? [pinnedColumn] : []),
    ],
  };

  const newHeaders = [selectionHeader, ...baseColumns].map((header) => ({
    ...header,
    ...headersSharedConfig,
  })) as TableHeaders[];

  const headersWithCustomCells = newHeaders.map((header) => {
    const { columns } = header;
    const columnsWithCustomCells = columns?.map((column: Column) => {
      const cellId = (
        column.questionContent ? column.questionContent.type : column.accessor
      ) as string;
      const customCell = customCellsMapper(cellId);
      if (customCell) return { ...column, Cell: customCell };
      return column;
    });
    return { ...header, columns: columnsWithCustomCells };
  });

  const columnsWithPinnedData = pinnedColumn
    ? headersWithCustomCells.map((header) => {
        if (header.id === pinnedHeaderId) return header;
        const { columns } = header;
        const newColumns = columns.filter((column) => {
          return column.accessor !== pinnedColumn.id;
        });
        return { ...header, columns: newColumns };
      })
    : headersWithCustomCells;

  return columnsWithPinnedData;
}
