import { opacify } from 'polished';
import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  padding: 2rem 1.5rem;
  border: 0.05rem solid
    ${({ theme }) => opacify(-0.95, theme.colors.black[500])}; // rgba(0, 0, 0, 0.05)
  border-radius: 0.438rem;
  box-shadow: 0 0 1.5rem 0 ${({ theme }) => theme.colorMappings.shadow};
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 0 2rem;
`;

export const BaseText = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  line-height: normal;
  margin: 0;
`;

export const FormControlWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;
`;

export const Label = styled.label`
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

export const inputStyles = css`
  border-radius: 0.5rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
  height: 3rem;
  width: 100%;
  padding-left: 0.938rem;
  position: relative;
  &::placeholder {
    color: ${({ theme }) => theme.colorMappings.placeholderColor};
    font-family: Source Sans Pro;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
  }
`;
export const Input = styled.input`
  ${inputStyles}

  & + button {
    // Targets dropdown/search buttons
    position: absolute;
    right: 0;
    height: 3rem;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colorMappings.focusedInputBorder};
    outline: 0.25rem solid
      ${({ theme }) => opacify(-0.8, theme.colorMappings.focusedInputBorder)};
    & + button {
      border-color: ${({ theme }) => theme.colorMappings.focusedInputBorder};
    }
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  width: 4rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 0.063rem solid ${({ theme }) => theme.colors.white[300]};
  background: ${({ theme }) => theme.colors.white[400]};
  cursor: pointer;
`;

export const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.625rem;

  @media (max-width: 525px) {
    flex-wrap: wrap;
  }
`;
