import styled from 'styled-components';
import { readableColor } from 'polished/lib';

export const TopicPillContainer = styled.div<{
  color: string;
  isSlider: boolean;
}>`
  display: flex;
  height: 1.875rem;
  padding: 0.5rem;
  min-width: 0;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.4375rem;
  color: ${({ color }) =>
    color === '#00A863' ? '#fff' : readableColor(color)};
  background-color: ${({ color }) => color} !important;
  cursor: pointer;
  ${({ isSlider }) => isSlider && 'position: relative;'}

  span {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
`;

export const TopicPillText = styled.div<{
  isTable: boolean;
  columnWidth: number;
  maxWidthResize: number;
}>`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  font-weight: 600;
  width: ${({ isTable }) => (isTable ? 'unset' : `7.1875rem`)};
  background: transparent !important;

  p {
    margin: 0;
    display: inline;
  }
`;

export const SimpleHoverModalTopic = styled.div<{
  isSlider: boolean;
  isRight: boolean;
}>`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white[500]} !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 9999;
  position: absolute;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
  ${({ isSlider, isRight }) => isSlider && !isRight && 'right: 0;'}
`;
