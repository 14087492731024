import * as React from 'react';
import {
  HalfCircleBackground,
  HalfCircleInside,
  Wrapper,
} from './GroupCell.styles';

interface SubGroupCellProps {
  value: string | number;
  cell: {
    row: {
      original: {
        subGroupColour: string;
        type: string;
        _id: string;
        contributionId: string;
      };
    };
  };
}

export const SubGroupCell: React.FC<SubGroupCellProps> = ({
  value,
  cell,
}: SubGroupCellProps) => {
  if (!value && value !== 0) return <span>-</span>;

  const {
    row: {
      original: { subGroupColour },
    },
  } = cell;

  return (
    <Wrapper>
      <HalfCircleBackground color={subGroupColour}>
        <HalfCircleInside />
      </HalfCircleBackground>
      <span>{String(value)}</span>
    </Wrapper>
  );
};
