import { theme } from 'Theme';
import { StatusPillsValues, MapStatusPillsValuesProps } from '../types';

export const mapStatusPillsValues = ({
  confirmed,
  pending,
  surveyed,
  anonymous,
}: MapStatusPillsValuesProps): Array<StatusPillsValues> => {
  return [
    {
      label: 'Confirmed',
      value: confirmed,
      color: theme.colors.green['A400'],
    },
    {
      label: 'Pending',
      value: pending,
      color: theme.colors.orange['A100'],
    },
    {
      label: 'Surveyed',
      value: surveyed,
      color: theme.colors.purple['800'],
    },
    {
      label: 'Anonymous',
      value: anonymous,
      color: theme.colors.purple['700'],
    },
  ];
};
