import * as React from 'react';
import format from 'date-fns/format';
import { DateCellProps } from 'Pages/dashboard/components/Table/types';
import { Wrapper, WrapperMinutesDate } from './DateCell.styles';

const DateCell: React.FC<DateCellProps> = ({ value }: DateCellProps) => {
  if (!value) return <span>-</span>;
  const date = !isNaN(Date.parse(value))
    ? new Date(value)
    : new Date(Number(value));
  const dateDayToYear = format(date, 'dd MMMM yyyy');
  const dateHoursToSecond = format(date, 'HH:mm:ss');
  return (
    <Wrapper>
      <span>{dateDayToYear}</span>
      &nbsp;
      <WrapperMinutesDate>{`${dateHoursToSecond} (GMT)`}</WrapperMinutesDate>
    </Wrapper>
  );
};

export { DateCell };
