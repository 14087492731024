import * as React from 'react';
import { SimpleSelectProps } from 'Client/components/molecules/SimpleSelect/types';
import {
  IconOption,
  ValueIconOption,
} from 'Client/components/molecules/SimpleSelect/components';
import { DropdownSelect } from './DropdownInput.styles';
import { DropdownIndicator } from '../DropdownIndicator/DropdownIndicator';

interface DropdownInputProps extends SimpleSelectProps {
  zIndexMenu?: number;
  zIndexInput?: number;
  validations?: { error: string };
}

export const DropdownInput = ({
  placeholder,
  options,
  zIndexInput = 2,
  zIndexMenu = 1,
  validations,
  ...props
}: DropdownInputProps) => {
  return (
    <DropdownSelect
      options={options}
      className="react-select-container"
      classNamePrefix="react-select"
      components={{
        Option: IconOption,
        SingleValue: ValueIconOption,
        DropdownIndicator,
      }}
      placeholder={placeholder}
      noFocusHighlight
      zIndexInput={zIndexInput}
      zIndexMenu={zIndexMenu}
      validations={validations}
      {...props}
    />
  );
};
