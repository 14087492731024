import styled from 'styled-components';
import { BaseText, FormWrapper } from '../../Shared.styles';

export const ProjectDetailsWrapper = styled(FormWrapper)`
  max-width: 34.5rem;
  gap: 1.5rem;
  margin-top: 2.125rem;
  margin-bottom: 9.375rem;
`;

export const UrlPlaceholder = styled.div`
  position: absolute;
  height: 3rem;
  right: 0;
  border-radius: 0 0.5rem 0.5rem 0;
  width: 40%;
  border: 0.063rem solid ${({ theme }) => theme.colors.green['A700']}; // '#00a85a' accessible brand green
  background: #e7f5ef;
  color: ${({ theme }) => theme.colors.green['A700']};
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 515px) {
    justify-content: flex-start;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  height: fit-content;
  align-items: center;
`;

export const SwitchText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
`;

export const SwitchContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
