import * as React from 'react';
import { CalendarIcon } from 'Atoms/Icons/CalendarIcon/CalendarIcon';
import { ErrorStatusOrHelperText } from 'Atoms';
import { UrlPlaceholder } from 'Client/pages/dashboard/settings/components/Forms/ProjectDetailsForm/ProjectDetailsForm.styles';
import { BoldSearchIcon } from 'Atoms/Icons';
import {
  Wrapper,
  InputWrapper,
  Input,
  Label,
  WithIcon,
  SearchIconContainer,
} from './TextInput.styles';
import { TextInputProps } from './types';

export const TextInput: React.FC<TextInputProps> = ({
  placeholder,
  label,
  onChange,
  onFocus,
  labelFontWeight = 400,
  type,
  gap = 0.5,
  value,
  labelIcon,
  marginTop = 0,
  maxWidth,
  isHovering = false,
  error = false,
  errorMessage,
  commonplacePlaceholder = false,
  disabled = false,
}) => {
  const inputRef = React.useRef(null);
  return (
    <Wrapper>
      <InputWrapper marginTop={marginTop} gap={gap}>
        {label && !labelIcon && (
          <Label fontWeight={labelFontWeight}>{label}</Label>
        )}
        {labelIcon && (
          <WithIcon>
            {labelIcon}
            <Label fontWeight={labelFontWeight}>{label}</Label>
          </WithIcon>
        )}
        <Input
          ref={inputRef}
          type={type}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          error={error}
          maxWidth={maxWidth}
          isHovering={isHovering}
          disabled={disabled}
        />
        {commonplacePlaceholder && (
          <UrlPlaceholder>.commonplace.is</UrlPlaceholder>
        )}
        {type === 'date' && (
          <CalendarIcon
            onClick={() => {
              if (!inputRef.current) return;
              inputRef.current.showPicker(); // Opens date picker
            }}
          />
        )}
        {type === 'search' && (
          <SearchIconContainer error={error} isHovering={isHovering}>
            <BoldSearchIcon color="#949494" width={24} height={24} />
          </SearchIconContainer>
        )}
      </InputWrapper>
      {error && errorMessage && (
        <ErrorStatusOrHelperText
          className="input-error-message"
          status={{ type: 'error', message: errorMessage }}
        />
      )}
    </Wrapper>
  );
};
