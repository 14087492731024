import * as React from 'react';
import { IconProps } from '../../types';

export const DynamicIcon: React.FC<IconProps> = ({
  width = 15,
  height = 16,
  color = 'black',
  viewBox = '0 0 15 16',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08059 4.69308C6.1049 4.6151 6.17314 4.5625 6.25 4.5625H8.75C8.80925 4.5625 8.86465 4.59393 8.89787 4.64641C8.93109 4.69888 8.9378 4.76556 8.9158 4.8244L8.15661 6.85417H9.82142C9.89365 6.85417 9.95876 6.90069 9.9864 6.97206C10.014 7.04342 9.99876 7.12556 9.94769 7.18018L6.01913 11.3816C5.96237 11.4423 5.87472 11.455 5.80488 11.4127C5.73503 11.3704 5.70015 11.2835 5.71962 11.2002L6.37843 8.38194H5.17857C5.12117 8.38194 5.06727 8.35243 5.03371 8.30263C5.00015 8.25283 4.99101 8.18882 5.00916 8.13058L6.08059 4.69308ZM6.37871 4.94444L5.42633 8H6.60714C6.66213 8 6.71405 8.02709 6.74789 8.07344C6.78173 8.1198 6.79372 8.18024 6.78038 8.23729L6.22246 10.624L9.39031 7.23611H7.89285C7.8336 7.23611 7.77821 7.20468 7.74499 7.1522C7.71177 7.09973 7.70505 7.03305 7.72706 6.97421L8.48624 4.94444H6.37871Z"
        fill={color}
      />
      <rect
        x="2.1875"
        y="2.6875"
        width="10.625"
        height="10.625"
        rx="5.3125"
        stroke={color}
        strokeWidth="0.625"
      />
    </svg>
  );
};
