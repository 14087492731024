import * as React from 'react';
import { readableColor } from 'polished';
import { AIIcon } from 'Atoms/Icons/TopicAIPill/AIIcon/AIIcon';
import { DynamicIcon } from 'Atoms/Icons/TopicAIPill/DynamicIcon/DynamicIcon';
import { ChevronDownIcon } from 'Atoms/Icons';
import { CheckIcon } from 'Atoms/LoadingButton/LoadingButton.styles';
import {
  checkLengthAndSetModal,
  HTMLElementEvent,
  MouseEventCustom,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import { PlusCircleIcon } from 'Atoms/Icons/PlusCircle/PlusCircleIcon';
import { MinusCircleIcon } from 'Atoms/Icons/MinusCircle/MinusCircleIcon';
import {
  TopicPillContainer,
  TopicPillText,
  SimpleHoverModalTopic,
} from './TopicPill.styles';

interface TopicPillProps {
  color: string;
  type: string;
  text: string;
  onClick?: () => void;
  dropdown?: boolean;
  selected?: boolean;
  isManage?: boolean;
  manageSelected?: boolean;
  isSlider?: boolean;
  isRight?: boolean;
  isTable?: boolean;
  columnWidth?: number;
}

export const TopicPill: React.FC<TopicPillProps> = ({
  color,
  type,
  text,
  onClick,
  dropdown,
  selected,
  isManage,
  manageSelected,
  isSlider,
  isRight,
  isTable,
  columnWidth,
}: TopicPillProps) => {
  const colorForIcons = color === '#00A863' ? '#fff' : readableColor(color);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <TopicPillContainer color={color} onClick={onClick} isSlider={isSlider}>
      {selected && (
        <span>
          <CheckIcon color={colorForIcons} />
        </span>
      )}
      <span>
        {type === 'AI' ? (
          <AIIcon color={colorForIcons} />
        ) : (
          <DynamicIcon color={colorForIcons} />
        )}
      </span>
      <TopicPillText
        isTable={isTable}
        columnWidth={columnWidth}
        maxWidthResize={text.length * 7}
        onMouseOut={() => setOpenModal(false)}
        onMouseOver={(
          e:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
        ) => {
          setOpenModal(() =>
            checkLengthAndSetModal(
              e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>,
              2
            )
          );
        }}
      >
        <p>{text}</p>
        {openModal && (
          <SimpleHoverModalTopic isSlider={isSlider} isRight={isRight}>
            {text}
          </SimpleHoverModalTopic>
        )}
      </TopicPillText>
      {dropdown && (
        <span>
          <ChevronDownIcon color={colorForIcons} />
        </span>
      )}
      {isManage && manageSelected && (
        <span>
          <MinusCircleIcon color={colorForIcons} />
        </span>
      )}
      {isManage && !manageSelected && (
        <span>
          <PlusCircleIcon />
        </span>
      )}
    </TopicPillContainer>
  );
};
