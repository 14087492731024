import {
  TopFilters,
  GlobalFilterItem,
  ContributionsFilters,
} from 'Shared/types/dashboard';

const handleTopFilters = (topFilters: TopFilters) => {
  const date = (() => {
    if (!topFilters.date || !topFilters.date.from || !topFilters.date.to) {
      return undefined;
    }
    return {
      from: new Date(
        new Date(topFilters.date.from).setUTCHours(0, 0, 0, 0)
      ).toISOString(),
      to: new Date(
        new Date(topFilters.date.to).setUTCHours(23, 59, 59, 999)
      ).toISOString(),
    };
  })();
  const filtersWithDate = {
    ...topFilters,
    date,
  };
  const mappedTopFilters = Object.keys(filtersWithDate).reduce(
    (accumulator, keyWithValue) => {
      const keyValue = filtersWithDate[keyWithValue];
      if (!keyValue) return accumulator;
      accumulator[keyWithValue] = keyValue;
      return accumulator;
    },
    {}
  );
  return mappedTopFilters;
};

const handleNestedFilters = (nestedFilters: Array<GlobalFilterItem>) => {
  const mappedNestedFilters = nestedFilters.map((filter) => {
    if (filter.category) {
      if (typeof filter.value !== 'string') return filter;
      //remove spaces
      const newValue = filter.value.split(' ').join('');
      return {
        ...filter,
        value: newValue,
      };
    }
    return filter;
  });
  return mappedNestedFilters;
};

export const mapFilters = (
  nestedFilters: Array<GlobalFilterItem>,
  topFilters: TopFilters
): ContributionsFilters => {
  const newTopFilters = handleTopFilters(topFilters);
  const newNestedFilters = handleNestedFilters(nestedFilters);
  return {
    nestedFilters: newNestedFilters,
    topFilters: newTopFilters,
  };
};
